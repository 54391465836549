import { Context, createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import CommitAndFulfilProcessor, {
  BasketItemPayload,
  FulfillerResponse,
  FulfillmentProcessor,
} from "postoffice-commit-and-fulfill";
import { useCommitAndFulfill } from "./CommitAndFulfill";
import { useDeviceAttributes } from "./DeviceAttributes";
import { authHeaders } from "./../user";
import { buildFulfillmentApiClient, FulfillmentResponse } from "postoffice-product-journey-api-clients";
import { useTransactionAPI } from "../transactionApi";
import { Entry, EntryCore, EntryResponse } from "../../../openapi/transaction-api-v2";
import { useGlobalState } from "../../GlobalState";
import {
  ServiceEvent,
  getEventTagMapping,
  IngenicoPedClient,
  setup as setupDeviceService,
  SupportedServices,
} from "postoffice-peripheral-management-service";

export interface ProcessorProps {
  basketItems: BasketItemPayload[];
  basketId: string;
}

export interface BasketEntryWithDescription {
  description: string;
  item: BasketItemPayload;
}
export interface ProcessorContextProps {
  processResult: boolean;
  basketId: string;
  basketItems: BasketEntryWithDescription[];
  processBasketItems(): void;
  addItemToBasket(description: string, i: BasketItemPayload): void;
  removeItemFromBasket(entryID: string): void;
  setBasketId: Dispatch<SetStateAction<string>>;
  getBasketItemByEntryID(id: string): BasketItemPayload | undefined;
  basketOpen: boolean;
  setBasketOpen: Dispatch<SetStateAction<boolean>>;
  clearBasket(): void;
}

const ProcessorContext: Context<ProcessorContextProps> = createContext<ProcessorContextProps>({
  processResult: false,
  basketId: "",
  basketItems: [],
  processBasketItems: (): void => {},
  addItemToBasket: (description: string, i: BasketItemPayload): void => {},
  removeItemFromBasket: (entryID: string): void => {},
  setBasketId: () => {},
  getBasketItemByEntryID: (entryID: string): BasketItemPayload | undefined => undefined,
  basketOpen: false,
  setBasketOpen: () => {},
  clearBasket: () => {},
});

export type TransactionGeneratorEntry = {
  description: string;
  item: EntryCore;
};

export function useProcessor() {
  const context = useContext(ProcessorContext);

  if (!context) {
    throw new Error("useProcessor must be used within a ProcessorProvider");
  }
  return context;
}

export function ProcessorProvider({ children }: { children: JSX.Element }): JSX.Element {
  const fulfillCtx = useCommitAndFulfill();
  const deviceAttributes = useDeviceAttributes();
  const transactionApi = useTransactionAPI(process.env.REACT_APP_SERVER_ROOT || "");
  const [processResult, setProcessResult] = useState(false);
  const [basketId, setBasketId] = useState("");
  const [basketItems, setBasketItems] = useState<TransactionGeneratorEntry[]>([]);
  const [processor, setProcessor] = useState<FulfillmentProcessor | undefined>(undefined);
  const [basketOpen, setBasketOpen] = useState(false);
  const { tokens, selectedDevice } = useGlobalState();

  const fulfillClient = buildFulfillmentApiClient(process.env.REACT_APP_SERVER_ROOT || "", "v1", () =>
    authHeaders(tokens[selectedDevice.id].idToken)
  );

  const POL_PED_TPV_OVERRIDE: string = process.env.REACT_APP_POL_PED_TPV_OVERRIDE || "";
  const POL_DEVICE_SERVER_HOST: string = process.env.REACT_APP_POL_DEVICE_SERVER_HOST || "";
  const POL_DEVICE_SERVER_SIMULATED: boolean = process.env.REACT_APP_POL_DEVICE_SERVER_SIMULATED === "true" || false;

  const devices = setupDeviceService({
    deviceServerHost: POL_DEVICE_SERVER_HOST,
    callbacks: {
      onDisplayUpdate: (event: ServiceEvent) => {
        if (event.service === SupportedServices.IngenicoPed) {
          // CT team to render prompts to clerk
          const eventTag = getEventTagMapping(event.message);
          console.log("Text to display", eventTag !== undefined ? eventTag : event.message);
        }
      },
    },
  });

  const terminalId = POL_PED_TPV_OVERRIDE
    ? POL_PED_TPV_OVERRIDE
    : `${deviceAttributes.branchID.substring(0, 6)}${deviceAttributes.nodeID}`;

  const pinPad = devices.buildClient(SupportedServices.IngenicoPed, {
    terminalId,
    // TODO: CT to provide
    clerkId: "TBC",
    useMock: POL_DEVICE_SERVER_SIMULATED,
  }) as IngenicoPedClient;

  async function loadExistingBasket() {
    try {
      const lastBasket = await transactionApi.getLastBasket();

      if (!lastBasket) {
        console.error("No last basket");
        return;
      }

      if (lastBasket.data.basket.basketCore.basketState === "BKC") {
        console.info("Last basket is closed - can't load it");
        return;
      }

      setBasketId(lastBasket.data.basket.basketCore.basketID);
      setBasketOpen(true);

      const entries: TransactionGeneratorEntry[] = lastBasket.data.entries.map((e: Entry) => ({
        description: "Previous Item",
        item: e.entryCore,
      }));
      setBasketItems(entries);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function run() {
      if (selectedDevice !== undefined) {
        await loadExistingBasket();
      }
    }

    if (deviceAttributes.nodeID !== 0 && deviceAttributes.branchID !== "") {
      setProcessor(
        CommitAndFulfilProcessor(
          transactionApi.client,
          {
            onCommitSuccess,
            onCommitError,
            onFulfillmentSuccess,
            onFulfillmentError,
          },
          fulfillClient,
          {
            ped: pinPad,
          }
        )
      );

      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceAttributes.branchID, deviceAttributes.nodeID, transactionApi.client, selectedDevice]);

  /** Basket functions */

  const addItemToBasket = (description: string, item: BasketItemPayload): void => {
    setBasketItems((prev) => [
      ...prev,
      {
        description,
        item,
      },
    ]);
  };

  const removeItemFromBasket = (entryID: string): void => {
    setBasketItems((items) => {
      return items.filter((item) => !(item.item.tokens.entryID === entryID));
    });
  };

  const getBasketItemByEntryID = (id: string): BasketItemPayload | undefined => {
    return basketItems.find((e) => e.item.tokens?.entryID === id)?.item;
  };

  /** Processor callbacks */

  const onCommitError = (data: BasketItemPayload, error: Error): void => {
    fulfillCtx.setCommitFailureText((t) => {
      return t + "\n" + JSON.stringify(error, null, 2);
    });
  };

  const onCommitSuccess = (commitedItem: BasketItemPayload, data: EntryResponse): void => {
    fulfillCtx.setCommitSuccessText((t) => {
      return t + "\n" + JSON.stringify(data, null, 2);
    });
  };

  const onFulfillmentError = (item: BasketItemPayload, errorResponse: FulfillerResponse | Error): void => {
    fulfillCtx.setFulfilmentFailureText((t) => {
      return t + "\n" + JSON.stringify(errorResponse, null, 2);
    });
  };

  const onFulfillmentSuccess = (item: BasketItemPayload, response: FulfillerResponse | FulfillmentResponse): void => {
    fulfillCtx.setFulfilmentSuccessText((t) => {
      return t + "\n" + JSON.stringify(response, null, 2);
    });
  };

  async function runProcessor() {
    const success = await processor?.process(
      basketId,
      basketItems.map((v) => v.item)
    );

    setProcessResult(!!success);
  }

  function clearBasket() {
    setBasketItems([]);
  }

  return (
    <ProcessorContext.Provider
      value={{
        processBasketItems: runProcessor,
        basketId,
        basketItems,
        processResult,
        addItemToBasket,
        removeItemFromBasket,
        setBasketId,
        getBasketItemByEntryID,
        basketOpen,
        setBasketOpen,
        clearBasket,
      }}
    >
      {children}
    </ProcessorContext.Provider>
  );
}
