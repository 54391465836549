/* eslint-disable */
/*
  
  useAPI hook is a tuple which provides a predictable state container for all api calls that are modeled via OpenAPI specification.
  USAGE: 
  Using the Open API model you will create an object for the request params

    const requestParams: RegisterDeviceRequest = {
    id: 'test',
    name: '1234'
  }

  This can then be used in a varitey of methods to handle api calls.

  API Call on component mount requires a the url to be set initially: 

  const [{ data, status, error, statusCode}, postDevice , refresh ] = useApi('devicePost', requestParams);

  API Call when called via function requires url to be empty and the second paramter in the tuple to be called:
  
  postDevice('devicePost');

  Refresh an API Call requires the third paramter in the tuple to be called;

  refresh()

*/

import { useEffect, useReducer, useRef, useState, Dispatch, SetStateAction } from "react";
// useAPI tuple return.
type ReturnType = [State, Dispatch<SetStateAction<string>>, () => void];

// Interface for Api state
interface State {
  status: "init" | "fetching" | "error" | "fetched";
  data?: any;
  error?: string;
  statusCode?: number;
  apiStatus?: string;
}
interface Cache<T> {
  [url: string]: T;
}

// discriminated union type
type Action =
  | { type: "FETCH_INIT" }
  | { type: "FETCH_SUCCESS"; payload: any; apiStatus?: number }
  | { type: "FETCH_FAILURE"; payload: string; apiStatus?: number };

// Helper to identify empty data objects when passed into Hook
function isEmpty(value: any) {
  return value == null || value.length === 0 || Object.keys(value).length === 0;
}

export function useApi<T = unknown>(
  apiServiceClient: () => Promise<any>,
  initialUrl?: string,
  options?: any,
  data?: any,
  method?: string,
  metadataEntry?: any
): ReturnType {
  const cache = useRef<Cache<T>>({});
  const cancelRequest = useRef<boolean>(false);
  const [url, setUrl] = useState<string>(initialUrl ?? "");
  const [refreshIndex, setRefreshIndex] = useState<number>(0);
  const initialState: State = {
    status: "fetching",
    error: undefined,
    data: data,
    statusCode: undefined,
    apiStatus: undefined,
  };

  // Sets Index to know when an api is refreshed
  const refresh = () => {
    setRefreshIndex(refreshIndex + 1);
  };

  // Keep state logic separated
  const fetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "FETCH_INIT":
        return { ...initialState, status: "fetching", apiStatus: "init" };
      case "FETCH_SUCCESS": {
        return {
          ...initialState,
          status: "fetched",
          data: action.payload,
          statusCode: action.apiStatus,
          apiStatus: "success",
        };
      }
      case "FETCH_FAILURE":
        return {
          ...initialState,
          status: "error",
          error: action.payload,
          statusCode: action.apiStatus,
          apiStatus: "error",
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    if (!url) {
      return;
    }

    if (!isEmpty(initialState.data) && refreshIndex === 0)
      dispatch({ type: "FETCH_SUCCESS", payload: initialState.data });
    const fetchData = async () => {
      // const apiService is ts-ignore as the openAPI configuration json mime throws an type error under all circumstances.
      const apiService = await apiServiceClient();
      dispatch({ type: "FETCH_INIT" });
      if (cache.current[url] && refreshIndex === 0) {
        dispatch({ type: "FETCH_SUCCESS", payload: cache.current[url] });
      } else {
        try {
          const params = options;
          let response: any = {};
          if (url === "deviceIDLinkDelete") {
            response = await apiService[url](...params);
          }
          if (method === "GET" && url === "simulatorV5SubJourneyGet") {
            response = await apiService[url](params.name, params.deviceId);
          } else if (url === "testResultsApiTestGet") {
            response = await apiService[url](params.api, params.test);
          } else if (method === "GET") {
            response = await apiService[url](params);
          } else if (method === "PUT") {
            //@ts-ignore
            response = await apiService[url](params, metadataEntry);
          } else {
            //@ts-ignore
            response = await apiService[url](params);
          }
          cache.current[url] = response.data;
          if (!cancelRequest) return;
          dispatch({ type: "FETCH_SUCCESS", payload: response.data, apiStatus: response.status });
        } catch (error) {
          if (!cancelRequest) return;
          dispatch({ type: "FETCH_FAILURE", payload: error.response.data.message, apiStatus: error.response.status });
        }
      }
    };

    if (url !== "" || refreshIndex > 0) {
      fetchData();
    }
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, refreshIndex]);
  return [state, setUrl, refresh];
}

export default useApi;
