import { Configuration } from "../../openapi/device-api/configuration";
import { DeviceApi } from "../../openapi/device-api/api";
import { SimulatorDeviceManagementApi } from "../../openapi/device-management-api/api";
import { APIDocumentsApi } from "../../openapi/api-documents/api";
import { Auth } from "aws-amplify";
import axios from "axios";
import apiConfig from "../../config";

const axiosInstance = axios.create();
const authAxiosInstance = axios.create();

// Gets JWT token from amplify and assigns it to OpenAPI Configuration Object
async function getAccessToken(basePath: string) {
  const idToken = (await Auth.currentSession()).getIdToken();
  const config = new Configuration();
  config.accessToken = idToken.getJwtToken();
  config.basePath = basePath;
  return config;
}

export const getDeviceApiClient = async () => {
  return new DeviceApi(await getAccessToken(apiConfig.api), undefined, axiosInstance);
};

export const getDeviceManagementApiClient = async () => {
  return new SimulatorDeviceManagementApi(
    await getAccessToken(apiConfig.deviceManagementApi),
    undefined,
    axiosInstance
  );
};

export const getApiDocumentsClient = async () => {
  const config = await getAccessToken(apiConfig.apiDocuments);
  authAxiosInstance.defaults.headers.common.Authorization = `Bearer ${config.accessToken}`;
  return new APIDocumentsApi(config, undefined, authAxiosInstance);
};
