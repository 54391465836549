const baseApiUrl =
  process.env.REACT_APP_STAGE === "local"
    ? "https://api.spm-dev.com"
    : `https://api.spm-${process.env.REACT_APP_STAGE}.com`;
const baseDomainUrl =
  process.env.REACT_APP_STAGE === "local"
    ? "http://localhost:8080"
    : `https://simulator.spm-${process.env.REACT_APP_STAGE}.com/apps`;

const apiConfig = {
  baseApi: baseApiUrl,
  api: `${baseApiUrl}/simulator`,
  deviceManagementApi: `${baseApiUrl}/device-management`,
  journeyApi: `${baseApiUrl}/user-journey`,
  apiDocuments: `${baseApiUrl}/ApiDocuments`,
  apps: {
    payzone: `${baseDomainUrl}/payzone/index.html`,
    counterterminal: `${baseDomainUrl}/counterterminal/index.html`,
    journeysandbox: `${baseDomainUrl}/journeysandbox/index.html`,
  },
  isProduction: process.env.REACT_APP_STAGE === "prod",
  isDevelopment: process.env.REACT_APP_STAGE === "dev",
  awsSimulatorConfig: {
    mandatorySignIn: true,
    region: "eu-west-2",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
};

export default apiConfig;
